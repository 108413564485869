import React from 'react';
import { Menu, makeStyles } from '@material-ui/core';

import SocialMediaIcons from './SocialMediaIcons';

const useStyles = makeStyles((theme) => ({
    menuPaper: {
        width: "100%",
        left: "0%!important"
    }
}));

const SocialMediaBar = ({ children, texto, link }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.preventDefault();
        setAnchorEl(null);
    };

    const CancelButton = () => (
        window.screen.availWidth <= 769
        ? (<>
            <hr/>
            <a href="#REA" style={{color: "black"}}>
            <div
                className="pl-1 text-center"
                onClick={handleClose}
            >
                Cancelar
            </div>
            </a>
        </>)
        : <></>
    );

    return(
        <React.Fragment>
            {children(handleOpen)}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={ window.screen.availWidth < 769 ? { paper: classes.menuPaper } : {}}
                style={{marginTop: "40px", width: "110%"}}
            >
                <div className="row">
                <div className="col-6">
                    <strong className="pl-1">Compartir</strong>
                </div>
                {
                    window.screen.availWidth > 769 &&
                    <div className="col-5" style={{textAlign: "right"}} onClick={handleClose}>
                        <a href="#REA"><i className="fa fa-close" style={{color: "black"}}></i></a>
                    </div>
                }
                </div>
                <SocialMediaIcons texto={texto} link={link} />
                <CancelButton/>
            </Menu>
        </React.Fragment>
    );
}

export default SocialMediaBar;
