import React from 'react'
import { createListFromArray, ordernarXMenor, showDeveloperMessage } from 'src/helpers';
import Titulo from './componentes/Titulo';
import Texto from './componentes/Texto';
import DestacadoCentro from './componentes/DestacadoCentro';
import DestacadoIzquierda from './componentes/DestacadoIzquierda';
import DestacadoPrincipal from './componentes/DestacadoPrincipal';
import BuscadorRecursos from './componentes/BuscadorRecursos';
import Embebido from './componentes/Embebido';
import Noticia from './componentes/Noticia';
import BreadCrumb from '../shared/breadcrumb';
import { ComponenteAgrupador } from './componentes/ComponenteAgrupador';
import { ComponenteBoton } from './componentes/ComponenteBoton';

const COMPONENTS_TYPES = {
  DESTACADO_PRINCIPAL: 1,
  DESTACADO_IMAGEN_CENTRO: 2,
  DESTACADO_IMAGEN_IZQUIERDA: 3,
  TITULO: 4,
  TEXTO: 5,
  BUSCADOR_RECURSOS: 6,
  EMBEBIDO: 7,
  NOTICIAS: 8,
  AGRUPADOR: 9,
  BOTON: 10,
}

const PortadaBreadCrumb = ({history, match}) => (
  <div className="container-fluid">
    <div className="col-xl-10 offset-xl-1 col-12">
        <BreadCrumb
          actualPosition={history.location.pathname.replace(/-/g, " ").replace(/\//g, "").toUpperCase()}
          history={history}
          match={match}
          previousPositions={['Inicio']}
        />
    </div>
  </div>
);

const Portada = (props) => {
  const { componentes, history, match } = props;
  const destacadosPrincipales = componentes.filter(c => c.type.id === COMPONENTS_TYPES.DESTACADO_PRINCIPAL);

  const RenderBreadCrumb = ({componentes}) => (
    componentes.length === 0
      ? <PortadaBreadCrumb match={match} history={history}/>
      : null
  );

  React.useEffect(() => {
    showDeveloperMessage('portada', componentes);
  }, [componentes]);


  return (
    <>
    {
      destacadosPrincipales.map(c => {
        return (
          <DestacadoPrincipal componente={c} key={c.id} />
        );
      })
    }
    <div className={destacadosPrincipales.length === 0 ? "main_content" : ""}>
      <RenderBreadCrumb componentes={destacadosPrincipales} pathname={history.location.pathname} />
      <div className="container-fluid mb-4">
      <div className="col-xl-10 offset-xl-1 col-lg-12">
        {
          createListFromArray(componentes, 'row').map(i => {
            let tamanioOcupado = 0;
            return (
              <div className="row" key={i}>
                {
                  componentes.filter(c => c.row === i).sort((a, b) => ordernarXMenor(a, b, 'col')).map(c => {
                    const offset = (c.col - tamanioOcupado - 1) * 3;
                    tamanioOcupado += c.size + offset / 3;
                    return (
                      <div className={"col-xl-" + 3 * c.size + (offset !== 0 ? " offset-xl-" + offset : "") + " mb-3"} key={c.id}>
                        { c.type.id === COMPONENTS_TYPES.TITULO && <Titulo componente={c} /> }
                        { c.type.id === COMPONENTS_TYPES.TEXTO && <Texto componente={c} /> }
                        { c.type.id === COMPONENTS_TYPES.DESTACADO_IMAGEN_IZQUIERDA && <DestacadoIzquierda componente={c} /> }
                        { c.type.id === COMPONENTS_TYPES.DESTACADO_IMAGEN_CENTRO && <DestacadoCentro componente={c} /> }
                        { c.type.id === COMPONENTS_TYPES.BUSCADOR_RECURSOS && <BuscadorRecursos componente={c} history={history} /> }
                        { c.type.id === COMPONENTS_TYPES.EMBEBIDO && <Embebido componente={c} /> }
                        { c.type.id === COMPONENTS_TYPES.NOTICIAS && <Noticia componente={c} /> }
                        { c.type.id === COMPONENTS_TYPES.AGRUPADOR && <ComponenteAgrupador componente={c} /> }
                        { c.type.id === COMPONENTS_TYPES.BOTON && <ComponenteBoton componente={c} /> }
                      </div>
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
      </div>
    </div>
    </>
  )
}

export default Portada
