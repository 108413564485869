import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { UncontrolledTooltip } from 'reactstrap';

const Noticia = ({ componente }) => {
    const { title, copete, publication_date, url, image_file } = componente;
/* 
    return (
    <a href={link ?? '#REA'} className="card-link" target="_self" rel="noopener noreferrer">
        <div className="card bg-card shadow-none jm-bgk-img2 p-10-0">
            <div className="card-body" style={{ color: 'black'}}>
                <div>{ReactHtmlParser(title ? title.replace(/!important/g, "") : '')}</div>
            </div>
        </div>
    </a>
)
    return <div>{componente.title}</div>;
 */
    const backgroundURL = `${process.env.REACT_APP_BASE_URL_API}/${image_file.filename}`
    const iconStyles = { ...JSON.parse(image_file.styles), backgroundImage: `url(${backgroundURL})`, height: '100%', width: '100%', backgroundSize: 'cover' }

    const date = (new Date(publication_date))
        .toLocaleDateString('es-ar', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            calendar: 'iso8601',
            timeZone: "America/Buenos_Aires"
        }
    );

    const allParagraphs = ReactHtmlParser(copete ? copete.replace(/!important/g, "") : '');

    return (
        <div className="card">
            <a href={url?? '#REA'} className="noticia-link" target="_self" rel="noopener noreferrer">
                <div className="noticia-body">
                    <div className='noticia-title' id={`noticia-title-${componente.id}`}>
                        <>{ReactHtmlParser(title ? title.replace(/!important/g, "") : '')}</>
                        <UncontrolledTooltip placement="right" target={`noticia-title-${componente.id}`} >
                            <div className="notMarginButton">{ ReactHtmlParser(title) }</div>
                        </UncontrolledTooltip>
                    </div>
                    <div className="noticia-img">
                        <div className="icono-home-xl" style={iconStyles}/>
                    </div>
                    <div className='noticia-copete' id={`noticia-copete-${componente.id}`}>
                            {allParagraphs.length > 0 && allParagraphs}
                        <UncontrolledTooltip placement="right" target={`noticia-copete-${componente.id}`} >
                            <div className="notMarginButton">{ ReactHtmlParser(copete) }</div>
                        </UncontrolledTooltip>
                    </div>
                </div>
                <div className="noticia-footer" style={{ fontSize: '15px' }}>
                    <div className='noticia-footer-autor'>
                        <span className='noticia-autor'>Por: </span>
                        <span>Plataforma Conectar Igualdad</span>
                    </div>
                    <span className='noticia-date'>{date}</span>
                </div>
            </a>
        </div>
    );
};

export default Noticia;
