import {
    GET_FAQS_BACKOFFICE,
    GET_FAQS_BACKOFFICE_SUCCESS,
    GET_FAQS_BACKOFFICE_FAILURE,
    BackofficeTypes
} from '../types';
import header from '../../util/mocks/header';
import footer from '../../util/mocks/footer';
import portadas from 'src/util/mocks/portadas';
import institucionales from 'src/util/mocks/institucionales';
import noticias from 'src/util/mocks/noticias';

const INIT_STATE = {
    header: header,
    footer: footer,
    portadas: null,
    institucionales: null,
    noticias: null,
    faqs: null,
    loading: true
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case BackofficeTypes.LOADING:
            return {
                ...state,
                loading: true
            }
        case BackofficeTypes.NOT_LOADING:
            return {
                ...state,
                loading: false
            }
        case BackofficeTypes.FETCH_HEADER:
            return {
                ...state,
                loading: true
            }
        case BackofficeTypes.FETCH_HEADER_SUCCESS:
            return {
                ...state,
                header: action.payload,
                loading: false
            }
        case BackofficeTypes.FETCH_HEADER_FAILURE:
            return {
                ...state,
                header: header,
                loading: false
            }
        case BackofficeTypes.FETCH_FOOTER:
            return {
                ...state,
                loading: true
            }
        case BackofficeTypes.FETCH_FOOTER_SUCCESS:
            return {
                ...state,
                footer: action.payload,
                loading: false
            }
        case BackofficeTypes.FETCH_FOOTER_FAILURE:
            return {
                ...state,
                footer: footer,
                loading: false
            }
        case BackofficeTypes.FETCH_PAGINAS:
            return {
                ...state,
                loading: true
            }
        case BackofficeTypes.FETCH_PAGINAS_SUCCESS:
            return {
                ...state,
                portadas: action.payload.portadas,
                institucionales: action.payload.institucionales,
                noticias: action.payload.noticias,
                loading: false
            }
        case BackofficeTypes.FETCH_PAGINAS_FAILURE:
            return {
                ...state,
                portadas: portadas,
                institucionales: institucionales,
                noticias: noticias,
                loading: false
            }
        case GET_FAQS_BACKOFFICE:
            return {
                ...state,
                loading: true
            }
        case GET_FAQS_BACKOFFICE_SUCCESS:
            return {
                ...state,
                faqs: action.payload,
                loading: false
            }
        case GET_FAQS_BACKOFFICE_FAILURE:
            return {
                ...state,
                faqs: [],
                loading: false
            }
        default: return { ...state };
    }
}
