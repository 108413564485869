import React from 'react';
import parseHTML from "html-react-parser";
import { Card, CardContent } from '@material-ui/core';

// import { createListFromArray, ordernarXMenor } from '../../helpers';
import { convertDate, showDeveloperMessage, stripHTML } from 'src/helpers';
import shareIcon from 'src/assets/images/shareIcon.svg';
import { unescapeHtml } from 'src/util/unescapeHtml';

import BreadCrumb from '../shared/breadcrumb';
import SocialMediaBar from '../shared/SocialMediaBar';
import CardNoticia from './componentes/Noticia';
import noticias from 'src/util/mocks/noticias';
import { BackofficeService } from 'src/axios';

const Noticia = ({ noticia, history, match }) => {
    React.useEffect(() => {
        showDeveloperMessage('noticia', noticia)
    }, [noticia]);

    const [lastNews, setLastNews] = React.useState(null);
    const textShare = stripHTML(noticia.title)

    React.useEffect(() => {
        async function fetchLastNews() {
            try {   
                const data = await BackofficeService.fetchLastNews();
                setLastNews(data);   
            } catch (error) {
                console.error('Error al buscar ultimas noticias', error);
                setLastNews([]);
            }
        }

        if (!lastNews) {
            fetchLastNews();
        }
    }, [lastNews, setLastNews]);

    return (
        <div className="main_content">   
            <div className="container-fluid">
                <div className="col-xl-10 offset-xl-1 col-12">
                    <BreadCrumb match={match} history={history} actualPosition={stripHTML(noticia.title)} previousPositions={['Inicio']} />
                </div>
            </div>
            <div className="col-12 col-xl-10 mb-5 mt-5 offset-xl-1">
                <div style={{ paddingLeft: '1rem' }}>
                    <h2 className="text-dark-grey mb-4 font-weight-bold recurso-title-no-transform">{parseHTML(noticia.title ?? '')}</h2>
                    <div>{parseHTML(noticia.copete ?? '')}</div>
                    
                    <div className="row mt-4 mb-4">
                        <div className="col-md-4 col-2 mt-2 ">
                            <SocialMediaBar texto={textShare} link={noticia.link}>
                                {
                                    (handleClick) => (
                                        <a href="#REA" title="Compartir en redes" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className="icon-bar detail">
                                            <img src={shareIcon} alt="Botón de compartir" width="24" height="24" onClick={(e) => e.preventDefault()} />
                                        </a>
                                    )
                                }
                            </SocialMediaBar>
                        </div>
                        <div className="col-md-8 col-10 text-right mt-3">
                            <p className="small"><strong>Por:</strong> Plataforma Conectar Igualdad {noticia.publication_date ? convertDate(noticia.publication_date, 'YYYY-MM-DD HH:mm:ss', 'D[/]MM[/]YYYY') : '01/01/1970'}</p>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="ck-content">{parseHTML(noticia.body ? unescapeHtml(noticia.body) : '')}</div>
                    </div>
                    {   
                        lastNews && 
                            <div className="col-12 col-lg-4 seccion-last-news">
                                <div className='last-news-container'>
                                        <h3 className='last-news-title'>Últimas noticias</h3>
                                        <div className='last-news-section m-3'>
                                            {lastNews.map((cardNoticia, index) => {
                                                return <div className={(index < lastNews.length - 1) ? 'mb-3' : ''}  style={{ width: '100%' }}>
                                                    <CardNoticia key={cardNoticia.id} componente={cardNoticia}/>
                                                </div>
                                            })}
                                        </div>
                                    {/* <Card style={{ boxShadow: 'none', borderColor: 'grey', borderStyle: 'solid', borderWidth: 'thin', borderRadius: '1rem' }}>
                                    <CardContent>
                                    </CardContent>
                                </Card> */}
                                </div>
                                {/* <Card style={{ boxShadow: 'none', borderColor: 'grey', borderStyle: 'solid', borderWidth: 'thin', borderRadius: '1rem' }}>
                                    <CardContent>
                                        <h3 className='last-news-title'>Últimas noticias</h3>
                                        <div className='last-news-container'>
                                            {noticia.last_news.map((cardNoticia) => {
                                                return <CardNoticia key={cardNoticia.id} componente={cardNoticia}/>
                                            })}
                                        </div>
                                    </CardContent>
                                </Card> */}
                            </div>
                        
                    }
                </div>
            </div>
        </div>
    );
}

export default Noticia

