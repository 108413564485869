import React from 'react';
import { useRouteMatch } from 'react-router-dom';
// import logo from '../../assets/images/logo_jm.svg';

import { showDeveloperMessage } from 'src/helpers';
import UserMenu from 'src/components/backoffice/usuario/UserMenu';
import Logo from 'src/components/backoffice/Logo';
import NavBar from './header/NavBar';

const Header = ({ options, logo }) => {
    const matchMantenimiento = useRouteMatch('/mantenimiento');
    const matchPreviewFooter = useRouteMatch('/preview/footer');

    const openMenu = () => {
        let menu = document.getElementById("navbarMain");
        if (menu.classList.contains('show')) {
            menu.classList.remove('show');
        } else {
            menu.classList.add('show');
        }
    }

    const closeMenu = () => {
        let menu = document.getElementById("navbarMain");
        if (menu.classList.contains('show')) {
            menu.classList.remove('show');
        }
    }

    React.useEffect(() => {
        showDeveloperMessage('header', {logo, menu: options});
    }, [options, logo]);

    return (
        !matchPreviewFooter && (
            <header>
                <nav className="pl-3 navbar navbar-expand-lg navbar-dark dark fixed-top">
                    <Logo className="logo-jm" logo={logo} target="_self" />
                    {
                        !matchMantenimiento &&
                        <>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMain" aria-controls="navbarMain" aria-expanded="false" aria-label="Toggle navigation"
                                onClick={() => {
                                    openMenu();
                                }}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarMain">
                                <NavBar
                                    closeMenu={closeMenu}
                                    options={options}
                                />
                                <UserMenu />
                            </div>
                        </>
                    }
                </nav>
            </header>
        )
    )
}

export default Header;
