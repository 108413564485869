import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import ReactHtmlParser from 'html-react-parser';

import { withStyles } from "@material-ui/core/styles";

import { getDefaultImage } from 'src/helpers/defaultImage';

import CardHeader from './card-header';
import CardBody from './card-body';

const styles = (theme) => ({
    font: {
        fontSize: '15px',
        fontFamily: 'Kanit, Bold',
        lineHeight: '20px'
    },
});

const CarouselCard = ({ classes, recurso }) =>
    <div name="recursoBody" 
        className="thumbnail card body-resource-height"
        style={{
            bottom: "2rem"
        }}>
        <CardHeader
            format={recurso.tags ? recurso.tags.format[0] : { id: 1 }}
            id={recurso.resource_id}
            src={getDefaultImage(recurso)}
        />

        <CardBody>
            <div className="overflow-header" style={{ WebkitLineClamp: "2", textOverflow: "ellipsis", paddingRight: "1rem", height: "auto" }}>
                <Link
                    to={`/recurso/${recurso.resource_id}`}
                    // id={`UncontrolledTooltip-${recurso?.resource_id ?? '0'}`}
                >
                    <div className={`h5 group card-title inner list-group-item-heading mb-0  ${classes.font}`}>
                        {ReactHtmlParser(recurso?.title ? recurso.title : '<p>null</p>')}
                    </div>
                </Link>

                {/* <UncontrolledTooltip placement="right" target={`UncontrolledTooltip-${recurso?.resource_id ?? '0'}`} >
                    <div className="notMarginButton">{ReactHtmlParser(recurso?.title ? recurso.title : '<p>null</p>')}</div>
                </UncontrolledTooltip> */}
                {/* <div className="notMarginButton">{ReactHtmlParser(recurso?.title ? recurso.title : '<p>null</p>')}</div> */}
            </div>
        </CardBody>
    </div>

export default withStyles(styles)(CarouselCard);
