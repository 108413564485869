import React from 'react';
import { useLocation } from 'react-router-dom';

import { MenuItem } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { isMobile } from 'src/helpers';

const SafeLink = ({children, ...props}) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </a>
)

const SocialMediaIcons = ({texto, link, ...props}) => {
  const shareText = encodeURIComponent(texto) + "%0D%0A" + "%0D%0A"
  const linkHeader = texto + "%0D%0A" + "%0D%0A" + link
  // const url = window.origin;
  // const location = useLocation();

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }} {...props}>
      <MenuItem className='pr-1 pl-1'>
        <SafeLink
          title="Compartir en Facebook"
          href={`https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${shareText}&amp;src=sdkpreparse`}
        >
          <FacebookIcon style={{ color: "#3B5998" }} />
        </SafeLink>
      </MenuItem>
      <MenuItem className='pr-1 pl-1'>
        <SafeLink
          href={`http://twitter.com/share?url=${link}&text=${shareText}&via=`}
          title="Compartir en Twitter"
          className="twitter-share-button"
          data-show-count="false"
        >
          <TwitterIcon style={{ color: "#1da1f2" }} />
        </SafeLink>
        <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
      </MenuItem>
      <MenuItem className='pr-1 pl-1'>
        <SafeLink 
          href={isMobile()
            ? `whatsapp://send?text=${shareText + encodeURI(link)}`
            : `https://web.whatsapp.com/send?text=${shareText + encodeURI(link)}`
          }
          title="Compartir en WhatsApp"
          data-action="share/whatsapp/share"
        >
          <WhatsAppIcon style={{ color: "#64d467" }} />
        </SafeLink>
      </MenuItem>
    </div>
  )
}

export default SocialMediaIcons