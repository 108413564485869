import { NotificationManager } from 'react-notifications'
import {isMobile} from 'react-device-detect'

export * from "./console";
export * from "./env";
export * from "./string";

export const convertDate = (dateString) => {
        return (new Date(dateString)).toLocaleDateString('es-ar', { 
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                calendar: 'iso8601',
                timeZone: "America/Buenos_Aires"
        })
}


export const copyToClipboard = containerid => {
        if (document.selection) {
                let range = document.body.createTextRange();
                range.moveToElementText(document.getElementById(containerid));
                range.select().createTextRange();
                document.execCommand("copy");
        } else if (window.getSelection) {
                let range = document.createRange();
                range.selectNode(document.getElementById(containerid));
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                document.execCommand("copy");
        }
        NotificationManager.info("Código copiado", null, 1500)
}

export const sameOrigin = (href) =>
        href.split('/')[2].split('.').splice(-3,3).join('.') === window.location.origin.split('/')[2].split('.').splice(-3,3).join('.');

export const mostrarAvisoDeSalida = (e) => {
        if(isMobile){
                e.preventDefault()
                if (window.confirm("Al dejar este espacio es posible que comiences a consumir datos de tu plan de conectividad. ¿Deseas continuar?")) {
                        window.open(e.currentTarget.href, '_self');
                }
        }
}

export const soportaDictadoPorVoz = () => {
        if (navigator.userAgent.indexOf("Chrome") !== -1 && navigator.userAgent.indexOf("Edg") === -1) {
                return true
        } else {
                return false
        }
}


export const windowsClick = (obj, evt, fn) => {
        if (obj.addEventListener) {
                obj.addEventListener(evt, fn, false);

        } else if (obj.attachEvent) {
                obj.attachEvent('on' + evt, fn)
        }

}

export const cleanUrl = (url) => {
        if (url.includes('#REA')) {
                let urlClean
                urlClean = url.split("#REA")
                return urlClean[0]
        }
        return url
}


export const getDomain = (url, event) => {
        var local = window.location.hostname.replace(/^[^.]+\./g, "");
        var jmanso = "https://recursos.juanamanso.edu.ar".replace(/^[^.]+\./g, "");
        var educar = "https://.educ.ar".replace(/^[^.]+\./g, "");
        var prefix = /^https?:\/\//i;
        var domain = /^[^/:]+/;
        url = url.replace(prefix, "");

        if (url.charAt(0) === "/") {
                url = window.location.hostname + url;
        }
        var match = url.match(domain);
        if (match) {
                var urlClick = match[0].replace(/^[^.]+\./g, "");

                if (urlClick.includes(jmanso) || urlClick.includes(educar) || urlClick.includes(local)) {
                } else {
                        if(isMobile){
                                if (window.confirm('Al dejar este espacio es posible que comiences a consumir datos de tu plan de conectividad.')) {
                                        return;
                                } else {
                                        event.preventDefault();
                                }
                        }
                }
        }

}

export function createListFromArray(arr, field) {
        let newList = [];
        arr.forEach(a => {
          if (a[field] && !newList.includes(a[field]))
            newList.push(a[field]);
        });
        return newList.sort();
}

export function ordernarXMenor(a, b, field) {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
};