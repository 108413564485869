import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Loading from 'src/components/shared/loading';
import { getHeaderBackoffice, getFooterBackoffice, getPaginasBackoffice } from 'src/redux/actions/backofficeActions';

import Header from './shared/Header';
import Footer from './shared/Footer';

const AppLayout = ({ backoffice, fetchHeader, fetchFooter, fetchPaginas, ...props }) => {

    useEffect(() => {
        const lastRefresh = localStorage.getItem("last_time_request_backoffice");
    
        if(!Boolean(lastRefresh)) {
            fetchHeader();
            fetchFooter();
            fetchPaginas();
        } else {
            const last_time = Math.round(parseInt(lastRefresh));
            const now = Math.round(Date.now() / 1000);
            const max_cache_time = parseInt(process.env.REACT_APP_CACHE_TIME_API_CONFS);

            if(now - last_time > max_cache_time) {
                fetchHeader();
                fetchFooter();
                fetchPaginas();
            }
        }
    }, [fetchHeader, fetchFooter, fetchPaginas]);

    return (
        (backoffice.loading || !backoffice.institucionales || !backoffice.portadas) ? (
            <Loading/>
        ) : (
            <React.Fragment>
                <Header options={backoffice.header.menu} logo={backoffice.header.logo} />
                {(backoffice.loading || !backoffice.institucionales || !backoffice.portadas) ? (
                    <Loading/>
                ) : (
                    props.children
                )}
                <Footer options={backoffice.footer.menu} logo={backoffice.footer.logo} logo_centro={backoffice?.footer?.logo_centro} text={backoffice.footer.text} />
            </React.Fragment>
        )
    )
}

const mapStateToProps = ({ backoffice }) => ({
    backoffice: backoffice,
});

const mapDispatchToProps = {
	fetchHeader: getHeaderBackoffice,
	fetchFooter: getFooterBackoffice,
	fetchPaginas: getPaginasBackoffice
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
