import {
    GET_PORTADA_BACKOFFICE,
    GET_PORTADA_BACKOFFICE_SUCCESS,
    GET_PORTADA_BACKOFFICE_FAILURE,
    GET_FAQS_BACKOFFICE,
    GET_FAQS_BACKOFFICE_SUCCESS,
    GET_FAQS_BACKOFFICE_FAILURE,
    GET_PAGINAINSTITUCIONAL_BACKOFFICE,
    GET_PAGINAINSTITUCIONAL_BACKOFFICE_SUCCESS,
    GET_PAGINAINSTITUCIONAL_BACKOFFICE_FAILURE,
    GET_NOTICIA_BACKOFFICE,
    GET_NOTICIA_BACKOFFICE_SUCCESS,
    GET_NOTICIA_BACKOFFICE_FAILURE,
    BackofficeTypes
} from '../types';
import { AuxService, BackofficeService } from 'src/axios';
import { showDeveloperError } from 'src/helpers';

export const ActionCreators = {
    setHeader: (header) => ({ type: BackofficeTypes.FETCH_HEADER_SUCCESS, payload: header }),
    setFooter: (footer) => ({ type: BackofficeTypes.FETCH_FOOTER_SUCCESS, payload: footer }),
    setPaginas: (paginas) => ({ type: BackofficeTypes.FETCH_PAGINAS_SUCCESS, payload: paginas }),
};

export const getHeaderBackoffice = () => (
    async (dispatch) => {
        dispatch({ type: BackofficeTypes.FETCH_HEADER })
        try {
            const menu  = BackofficeService.fetchHeaderMenu();
            const logo  = BackofficeService.fetchHeaderLogo();

            dispatch(
                ActionCreators.setHeader({
                    menu: (await menu),
                    logo: (await logo)
                })
            );
        } catch (error) {
            dispatch({ type: BackofficeTypes.FETCH_HEADER_FAILURE })
            console.log("Error al obtener la data del header: ", error)
        }
    }
)
export const getFooterBackoffice = () => (
    async (dispatch) => {
        dispatch({ type: BackofficeTypes.FETCH_FOOTER })
        try {
            const menu  = BackofficeService.fetchFooterMenu();
            const logo  = BackofficeService.fetchFooterLogo();
            const logo2 = await BackofficeService.fetchFooterLogoCentro().catch((error) => console.log(error));
            const text  = BackofficeService.fetchFooterText();

            dispatch(
                ActionCreators.setFooter({
                    menu: (await menu),
                    logo: (await logo),
                    logo_centro: (await logo2),
                    text: (await text).text
                })
            );
        } catch (error) {
            dispatch({ type: BackofficeTypes.FETCH_FOOTER_FAILURE })
            console.log("Error al obtener la data del footer: ", error)
        }
    }
)

export const getPaginasBackoffice = () => (
    async (dispatch) => {
        dispatch({ type: BackofficeTypes.FETCH_PAGINAS })
        try {
            const institucionales   = BackofficeService.fetchInstitucionales();
            const portadas          = BackofficeService.fetchPortadas();
            const noticias          = BackofficeService.fetchNoticias();

            dispatch(
                ActionCreators.setPaginas({
                    institucionales: (await institucionales),
                    portadas: (await portadas),
                    noticias: (await noticias),
                })
            );
        } catch (error) {
            dispatch({ type: BackofficeTypes.FETCH_PAGINAS_FAILURE })
            showDeveloperError("Error al obtener las páginas del backoffice: ", error);
        }
    }
)

export const getPortadaDataBackoffice = (portadaId) => async (dispatch) => {
    dispatch({ type: GET_PORTADA_BACKOFFICE })
    try {
        const result = await AuxService.getPortadaDataBackoffice(portadaId)
        dispatch({
            type: GET_PORTADA_BACKOFFICE_SUCCESS,
            payload: result.data
        })
    } catch (error) {
        dispatch({ type: GET_PORTADA_BACKOFFICE_FAILURE })
        console.log("Error al obtener la data de la portada: ", error)
    }
}

export const getFAQSBackoffice = () => async (dispatch) => {
    dispatch({ type: GET_FAQS_BACKOFFICE })
    try {
        const result = await AuxService.getFAQS()
        dispatch({
            type: GET_FAQS_BACKOFFICE_SUCCESS,
            payload: result.data
        })
    } catch (error) {
        dispatch({ type: GET_FAQS_BACKOFFICE_FAILURE })
        console.log("Error al obtener la data de las FAQS: ", error)
    }
}

export const getTempPaginaInstitucionalDataBackoffice = (paginaId) => async (dispatch) => {
    dispatch({ type: GET_PAGINAINSTITUCIONAL_BACKOFFICE })
    try {
        const result = await AuxService.getTempPaginaInstitucionalDataBackoffice(paginaId)
        dispatch({
            type: GET_PAGINAINSTITUCIONAL_BACKOFFICE_SUCCESS,
            payload: result.data
        })
    } catch (error) {
        dispatch({ type: GET_PAGINAINSTITUCIONAL_BACKOFFICE_FAILURE })
        console.log("Error al obtener la data de la página institucional: ", error)
    }
}

export const getPaginaInstitucionalDataBackoffice = (paginaId) => async (dispatch) => {
    dispatch({ type: GET_PAGINAINSTITUCIONAL_BACKOFFICE })
    try {
        const result = await AuxService.getPaginaInstitucionalDataBackoffice(paginaId)
        dispatch({
            type: GET_PAGINAINSTITUCIONAL_BACKOFFICE_SUCCESS,
            payload: result.data
        })
    } catch (error) {
        dispatch({ type: GET_PAGINAINSTITUCIONAL_BACKOFFICE_FAILURE })
        console.log("Error al obtener la data de la página institucional: ", error)
    }
}

export const getTempNoticiasDataBackoffice = (paginaId) => async (dispatch) => {
    dispatch({ type: GET_NOTICIA_BACKOFFICE })
    try {
        const result = await AuxService.getTempNoticiasDataBackoffice(paginaId)
        dispatch({
            type: GET_NOTICIA_BACKOFFICE_SUCCESS,
            payload: result.data
        })
    } catch (error) {
        dispatch({ type: GET_NOTICIA_BACKOFFICE_FAILURE })
        console.log("Error al obtener la data de la noticia: ", error)
    }
}

export const getNoticiasDataBackoffice = (paginaId) => async (dispatch) => {
    dispatch({ type: GET_NOTICIA_BACKOFFICE })
    try {
        const result = await AuxService.getNoticiasDataBackoffice(paginaId)
        dispatch({
            type: GET_NOTICIA_BACKOFFICE_SUCCESS,
            payload: result.data
        })
    } catch (error) {
        dispatch({ type: GET_NOTICIA_BACKOFFICE_FAILURE })
        console.log("Error al obtener la data de la noticia: ", error)
    }
}