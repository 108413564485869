import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';

// import ministerioEducacionLogo from "../../assets/images/logo_mined.svg";
import { showDeveloperMessage } from 'src/helpers';
import Logo from 'src/components/backoffice/Logo';
import FootBar from './footer/FootBar';

const Footer = ({ options, text, logo, logo_centro }) => {
    const matchMantenimiento = useRouteMatch('/mantenimiento');
    const matchPreviewHeader = useRouteMatch('/preview/header');
    const hasLogoCentro = Boolean(logo_centro?.link);

    React.useEffect(() => {
        showDeveloperMessage('footer', {logo, menu: options});
    }, [logo, options]);

    return (
        !matchPreviewHeader && (
            <footer className="footer mt-auto pb-0 pt-3">
                <div className="container-fluid pb-0 mb-0 no-gutters">
                    <div className="col-md-12">
                        <div className="row">
                            <div className={`${hasLogoCentro ? "col-lg-4" : "col-lg-6"} offset-lg-0 col-10 offset-1`}>
                                <Logo className="logos-footer" logo={logo} target="_self" />
                            </div>
                            {hasLogoCentro &&(
                                <div className={`col-lg-4 offset-lg-0 col-10 offset-1`} style={{ textAlign: "center" }}>
                                    <Logo className="logos-footer" logo={logo_centro} target="_self" />
                                </div>
                            )}
                            <div className={`${hasLogoCentro ? "col-lg-4" : "col-lg-6"} col-12 mt-1 footerDatos pl-2 pr-4`}>
                                {!matchMantenimiento && <FootBar options={options} />}
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12 text-center ck-content">
                                {ReactHtmlParser(text ?? '')}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    )
}
export default Footer;