import React from 'react';
import { sameOrigin, mostrarAvisoDeSalida } from 'src/helpers';

const Logo = ({ className, logo, target }) => {
    const rel = target === '_blank' ? "noopener noreferrer" : "";

    if(!logo?.link)
        return null;

    return (
        <a
            href={logo.link}
            target={target}
            onClick={sameOrigin(logo.link) ? () => {} : mostrarAvisoDeSalida}
            rel={rel}
        >
            <img
                className={className}
                src={`${process.env.REACT_APP_JM_STATIC_DOMAIN}/${logo.url}?${Date.now()}`}
                style={JSON.parse(logo.styles)}
                alt={logo.alt_text}
                height="100px"
            />
        </a>
    );
}

export default Logo;
